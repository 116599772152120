import { useContext } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { signOut } from 'aws-amplify/auth'
import { Button, Icon } from '@sh24/ui-components'
import SessionContext from '../../contexts/session-context'

const AccountNavHeading = styled.h3`
  ${({ theme }) => `
    display: inline-flex;
    justify-content: center;
    align-items: center;

    margin: 0;

    svg {
      margin-right: ${theme?.spacing?.sm};
    }
  `}
`

const AccountLink = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;

    svg {
      margin-right: ${theme?.spacing?.sm};
    }
  `}
`

const AccountMenu = ({
  translations,
  loggedInAccountMenuWithLinks,
  loggedOutAccountMenuWithLinks,
  setNavActive,
  setAccountNavActive,
}) => {
  const { user, setUser } = useContext(SessionContext)
  const { push, asPath, pathname } = useRouter()

  // if logged out and logged out menu is not set
  if (!user && !loggedOutAccountMenuWithLinks) return null

  // if logged in and logged in menu is not set
  if (user && !loggedInAccountMenuWithLinks) return null

  const menu = user ? loggedInAccountMenuWithLinks : loggedOutAccountMenuWithLinks

  const onSignOut = async () => {
    await signOut()
    setUser?.(null)
    setNavActive(false)
    setAccountNavActive(false)
    if (pathname.includes('/account')) {
      push('/account')
    }
  }

  const loggedInPrependedMenuItems = [
    <AccountNavHeading key="heading">
      <Icon name="user" />
      {translations['accountMenu.title']}
    </AccountNavHeading>,

    <Button
      text={translations['accountMenu.signOut']}
      onClick={onSignOut}
      fullWidth
      key="signout-button"
    />,
  ]

  const loggedOutPrependedMenuItems = [
    <AccountNavHeading key="heading">
      <Icon name="user" />
      {translations['accountMenu.title']}
    </AccountNavHeading>,
    <Button
      text={translations['accountMenu.signIn']}
      onClick={async () => {
        sessionStorage.setItem('redirectUrl', asPath)
        setNavActive(false)
        setAccountNavActive(false)
        push('/account/login')
      }}
      fullWidth
      key="login-button"
    />,
    <Button
      text={translations['accountMenu.signUp']}
      variation="secondary"
      onClick={async () => {
        sessionStorage.setItem('redirectUrl', asPath)
        setNavActive(false)
        setAccountNavActive(false)
        push('/account/sign-up/email')
      }}
      fullWidth
      key="signup-button"
    />,
  ]

  return {
    navItem: {
      id: user ? 'login-account-menu' : 'logout-account-menu',
      name: translations['accountMenu.title'],
      link: <AccountLink><Icon name="user" /> {translations['accountMenu.title']}</AccountLink>,
    },
    menuOpenText: translations['accountMenu.title'],
    menu: {
      ...menu,
      preMenuItems: [
        ...(user ? loggedInPrependedMenuItems : loggedOutPrependedMenuItems),
        ...menu.preMenuItems,
      ],
    },
  }
}

export default AccountMenu
